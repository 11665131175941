.payment-options {
    display: flex;
    flex-direction: column;
}

.payment-options .text {
    font-family: Inter;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    margin-bottom: 16px;
}

.payment-options .card {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    padding: 20px 22px 20px 24px;
    border-radius: 24px;
}

.payment-options .card.green {
    background-color:#81d553;
}

.payment-options .card.yellow {
    background-color:#ede74e;
}

.payment-options .card .card-title {
    margin-bottom: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #06285e;
}

.payment-options .card .card-title span {
    font-weight: 600;
}

.payment-options .card .card-header {
    display: flex;
    justify-content: space-between;
}

.payment-options .card .card-header .card-title {
    font-family: SpaceGrotesk;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: -0.79px;
    text-align: left;
    color: #fff;
}

.payment-options .card .card-header img {
    width: 32px;
    height: 32px;
}

.payment-options .card .card-subtitle {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #06285e;
}

.payment-options .card .card-subtitle span {
    font-weight: 600;
}

.payment-options .card .buttons .button {
    width: 100%;
    background-color: #fff;
    font-family: SpaceGrotesk;
    font-size: 12.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #0052ca;
    height: 40px;
}

.user-error {
    color: red;
}

.payment-options-buttons {
    margin-top: 20px;
}

.payment-options-buttons .button, .payment-options-buttons .button:focus, .payment-options-buttons .button:hover {
    font-family: SpaceGrotesk;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #0052ca;
    background-color: white;
    border: none;
    text-transform: uppercase;
}

.payment-options-dialog-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
}

.payment-options-form-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    height: 50px;
}



.payment-options-form-item-label {
    width: 20%;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    display: flex;
    align-items: center;

}

.payment-options-form-item-field {
    width: 100%;
}

.payment-options-form-item-field input {
    border: 0;
    border-bottom: 1px solid #06285e;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.22px;
    text-align: left;
    color: #06285e;
}

.payment-options-form-item-field .p-dropdown {
    border: 0;
    border-bottom: 1px solid #06285e;
}

.payment-options-form-item-field .p-dropdown-label {
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.22px;
    text-align: left;
    color: #06285e;
}

.tabs-content .p-dialog-header {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 40px;
    padding-bottom: 10px;
}

.tabs-content .p-dialog-content {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 40px;
    padding-top: 0;
}

.payment-options-form-item-fullline {
    margin-top: 10px;
}