.profile-information {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.profile-information .text {
    font-family: Inter;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.profile-information .form {
    margin-top: 13px;
    margin-bottom: 13px;
}

.profile-information .form .form-field {
    width: 100%;
    border-bottom: 1px solid #c7c8ca;
    height: 48px;
}

.profile-information .form .form-field input, .profile-information .form .form-field input:focus {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.22px;
  text-align: left;
  color: #06285e;
}

.profile-information .buttons {
    margin-top: auto;
}

.profile-information .buttons .button{
    width: 100%;
    height: 50px;
    padding: 21px 23px 20px 22px;
    background-color: #0052ca;
    font-family: SpaceGrotesk;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 50px;
}

.profile-information .user-error {
    width: 100%;
    color: red;
    margin-top: 5px;
    margin-bottom: 5px;
}