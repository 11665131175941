body {
    
    display: flex;
    font-family: 'SpaceGrotesk';
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    

}

.App.external {
    background-image: none;
    color: #0052CB;
}

.App.internal {
    background-image: none;
    color: #fff;
}

.screen {
    position: relative;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0px;
}

.screen-segment {
    width: 100%;
    height: auto;
    padding: 0;
    padding-left: 10vw;
    padding-right: 10vw;
}

.header {
    position: absolute;
    top: 10vw;
    left: 0px;
    padding-left: 10vw;
    padding-right: 10vw;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.header .logo {
    width: 40vw;
}

.header .logo img {
    width: 100%;
}

.header .cogs {
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.header .cogs img {
    width: 100%;
    height: 100%;
}

.header .button {
    border: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    color: #0052ca;
    font-family: Inter;
    font-size: 3vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    text-align: right;
}

.header .p-button .p-button-icon-left {
    font-size: 2vw;
}

.header .user-info {
    display: none;
    flex-direction: row;
    align-items: center;
}

.header .user-info .name-and-logout {
    display: flex;
    flex-direction: column;
    margin-right: 21px;
}

.header .user-info .name-and-logout .name {
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    text-align: left;
    color: #06285e;
}

.header .user-info .name-and-logout .logout {
    
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    text-align: right;
    color: #06285e;
    cursor: pointer;
    text-decoration: underline;

}

.header .user-info .name span {
    color: #0052ca;
    font-weight: bold;
}

.header .user-info img {
    width: 43px;
    height: 43px;
    cursor: pointer;
}

