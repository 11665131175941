.screen.partner-thank-you {
    background-color: #fff;
    height: 100%;
}

.screen.partner-thank-you .screen-segment.left {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 200px;
}

.screen.partner-thank-you .screen-segment.left .title {
    font-family: Inter;
    font-size: 4vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
}

.screen.partner-thank-you .screen-segment.left .text {
    margin-top: 20px;
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.screen.partner-thank-you .screen-segment.left .buttons .button {
    margin-top: 20px;
    width: 100%;
    height: 57px;
    font-family: SpaceGrotesk;
    font-size: 4vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
