.screen.login {
    align-items: center;
}
.screen.login .screen-segment {
    width: 50%;
}

.screen.login .screen-segment.left {
    width: 673px;
    margin-left: 165px;
}

.screen.login .screen-segment.left .title {
    font-family: SpaceGrotesk;
    font-size: 65px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -1.3px;
    text-align: left;
    color: #fff;
  }

.screen.login .screen-segment.left .title .bold {
    color: #ede74e;
}

.screen.login .screen-segment.left .subtitle {
    margin-top: 35px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #fff;
  }

  .screen.login .screen-segment.left .subtitle a, a:visited{
    color: white;
  }

  .screen.login .screen-segment.left .form-box {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
  }

  .screen.login .screen-segment.left .form-box input {
    height: 50px;
    width: 49%;
    border-radius: 0;
    border: none;
}
  

  .screen.login .screen-segment.left .buttons .button {
    width: 673px;
  }

  .screen.login .screen-segment.left .footer-message {
    margin-top: 22px;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.22px;
    text-align: left;
    color: #fff;
  }

  .screen.login .screen-segment.left .footer-message .login {
    color: #ede74e;
    cursor: pointer;
  }

  .screen.login .screen-segment.left .footer-message a, .screen.login .screen-segment.left .footer-message a:visited, .screen.login .screen-segment.left .footer-message a:hover {
      color: #fff;
      text-decoration: none;
  }

.screen.login .screen-segment.right {
    position: relative;
    height: 100%;
}

.screen.login .screen-segment.right .card {
    position: absolute;
    padding: 48px;
    padding-right: 0px;
    width: 528px;
    height: 306px;
    border-radius: 49px;
    box-shadow: 0px 27px 50.7px 6.3px rgba(0, 0, 0, 0.33);
}

.screen.login .screen-segment.right .card .icon {
    width: 85px;
    height: 67px;    
}

.screen.login .screen-segment.right .card .icon img {
    width: 100%;
    height: 100%;
}

.screen.login .screen-segment.right .card .value {
    position: absolute;
    width: 225px;
    height: 114px;    
    font-family: Inter-ExtraLight;
    font-size: 124.5px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -7.23px;
    text-align: right;
    color: #fff;
    bottom: 48px;
    right: 50px;
}

.screen.login .screen-segment.right .card .value span {
    font-size: 72.5px;
    font-weight: 300;
    letter-spacing: -3.63px;
}

.screen.login .screen-segment.right .card.green {
    top: 150px;
    left: 100.1px;
    transform: rotate(-0.03turn);
    z-index: 1000;
    background-image: linear-gradient(to bottom right, #bfe97e, #81d553);
}

.screen.login .screen-segment.right .card.green .value {
    font-size: 123px;
    letter-spacing: -7.15px;
    right: 50px;
}

.screen.login .screen-segment.right .card.yellow {
    left: 247px;
    top: 310px;
    background-color: #ede74e;
}

.screen.login .screen-segment.right.desktop {
    display: flex;
}

.screen.login .screen-segment.right.mobile {
    display: none;
}