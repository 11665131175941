@import 'prismjs/themes/prism-coy.css';
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';

// --- Primereact overriding ---
.p-dialog-content {
    overflow-y: unset;
}

.p-button {
    font-family: 'Inter';
     color: #fff;
 }
 
 .p-button:enabled:hover, .p-button:enabled:focus {
    font-family: 'Inter';
     color: #fff;
     outline: none;
     box-shadow: none;
   }

   .p-password-panel {
    padding: 12px;
    width: 80%;
    text-align: center;
}

.p-input:enabled:focus {
    outline: none;
    box-shadow: none;
}

@keyframes ui-progress-spinner-color {
    100% { stroke: #0052CB !important; }
    0% { stroke: #0052CB !important; }
    40% { stroke: #0052CB !important; }
    66% { stroke: #0052CB !important; }
    80% { stroke: #0052CB !important; }
    90% { stroke: #0052CB !important; }
}

.p-progress-spinner-circle {
    stroke: #0052CB;
}

.p-button.p-button-secondary {
    background-color: #80C1FF;
    border-color: #80C1FF;
    font-family: 'Inter';
    color: #fff;
}

.p-button.p-button-secondary:enabled:hover {
    background-color: #80C1FF; 
    border-color: #80C1FF;
    font-family: 'Inter';
    color: #fff;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

input:enabled:focus, textarea:enabled:focus, select:enabled:focus{
    outline: none;
}

input:focus {
    outline: none;
    box-shadow: none;
}

.p-inputtext:enabled:focus {
    outline: none;
    box-shadow: none;;
}
// --- Primereact overriding --- End

.formfield {
    font-family: 'Inter';
    color: #06285e;
    padding: 0px;
    padding-left: 0px;
    height: 78px;
}

.formfield input {
    padding: 30px;
    width: 100%;
    height: 100%;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    color: #06285e;
    border-color: #fff;
    border-radius: 0;
}

.formfield input:focus {
    outline: none;
}

.formfield input::placeholder {
    font-family: 'Inter';
    color: #06285e;
}

.fieldErrorMessage {
    margin-top: 10px;
    color: red;
    font-family: Inter;
    font-size: 24px;
}

.buttons {
    display: flex;
    margin-top: 16px;
    width: 100%;
    height: auto;
    
  }

 .buttons .button, .buttons .button:hover, .buttons .button:focus {
    height: 78px;
    padding: 31px;
    background-color: #06285e;
    border: none;
    border-radius: 0;
    font-family: SpaceGrotesk;
    font-size: 26.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    outline: none;
  }

@import 'css/desktop/base.scss';
@import 'css/desktop/welcome.scss';
@import 'css/desktop/login.scss';
@import 'css/desktop/home.scss';
@import 'css/desktop/tabs.scss';
@import 'css/desktop/profile-information.scss';
@import 'css/desktop/earnings.scss';
@import 'css/desktop/payment_options.scss';
@import 'css/desktop/oboarding.scss';
@import 'css/desktop/profile.scss';
@import 'css/desktop/partner-intro.scss';
@import 'css/desktop/partner-form.scss';
@import 'css/desktop/partner-thankyou.scss';

@media screen and (max-width: 1680px) {
    @import 'css/13inch/base.scss';
    @import 'css/13inch/welcome.scss';
    @import 'css/13inch/login.scss';
    @import 'css/13inch/home.scss';
    @import 'css/13inch/tabs.scss';
    @import 'css/13inch/profile-information.scss';
    @import 'css/13inch/earnings.scss';
    @import 'css/13inch/payment_options.scss';
    @import 'css/13inch/oboarding.scss';
    @import 'css/13inch/profile.scss';
    @import 'css/13inch/partner-intro.scss';
    @import 'css/13inch/partner-form.scss';
    @import 'css/13inch/partner-thankyou.scss';

    .fieldErrorMessage {
        margin-top: 10px;
        color: red;
        font-family: Inter;
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    @import 'css/mobile/base.scss';
    @import 'css/mobile/welcome.scss';
    @import 'css/mobile/login.scss';
    @import 'css/mobile/home.scss';
    @import 'css/mobile/tabs.scss';
    @import 'css/mobile/profile-information.scss';
    @import 'css/mobile/earnings.scss';
    @import 'css/mobile/payment_options.scss';
    @import 'css/mobile/oboarding.scss';
    @import 'css/mobile/profile.scss';
    @import 'css/mobile/partner-intro.scss';
    @import 'css/mobile/partner-form.scss';
    @import 'css/mobile/partner-thankyou.scss';

    .fieldErrorMessage {
        margin-top: 5vw;
        color: red;
        font-family: Inter;
        font-size: 4vw;
    }
}
/*
body {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SpaceGrotesk';
    width: 100%;
    color: #0052CB;
}

#root {
    width: 100%;
}

.App {
    width: 100%;
    min-width: 800px;
    padding: 24px;
}

#logo {
    width: 250px;
}

.AppContent {
    width: 100%;
    padding: 24px;
}

.header {
    padding: 12px;
}

.header .title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
}

.main-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.main-subtitle {
    font-family: 'Inter';
    color: #58595B;
    font-size: 16px;
    font-weight: normal;
    margin-left: 24px;
    margin-right: 24px;
    line-height: 30px;
    text-align: center;
}

.header .user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #0052CB;
    font-size: 14px;
    font-weight: bold;
}

.header .user-info a {
    cursor: pointer;
}

.screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
}

.screen .title {
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
}

.screen .subtitle {
    color: #0052CB;
    font-size: 20px;
    margin-left: 24px;
    margin-right: 24px;
}

.form-box {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
}

.form-box .title {
    font-family: 'Inter';
    color: #58595B;
    font-size: 20px;
    font-weight: normal;
    margin-left: 24px;
    margin-right: 24px;
    line-height: 30px;
    text-align: center;
}

.form-box .buttons {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-box .buttons .button {
    margin-left: 12px;
    margin-right: 12px;
}



.fieldErrorMessage {
    padding: 12px;
    font-size: 12px;
    display: flex;
    color: red;
    align-items: center;
    justify-content: center;
}

.formfield {
    font-family: 'Inter';
    color: #58595B;
    padding: 5px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formfieldtitle {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    display: flex;
    justify-content: center;
}

.formfield input {
    padding: 12px;
    width: 80%;
    text-align: center;
    font-family: 'Inter';
    color: #58595B;
    border-color: #58595B;
}



.formfield div {
    justify-content: center;
}

.formfield-label {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome .terms-and-conditions-message {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    text-align: center;
}

.welcome .terms-and-conditions-message .message {
    margin-left: 12px;
}

.welcome .terms-and-conditions-message .message a {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.welcome .existing-account-message {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.welcome .existing-account-message a:any-link {
    font-family: 'Inter';
    color: #58595B;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.login .existing-account-message {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.login .existing-account-message a:any-link {
    font-family: 'Inter';
    color: #58595B;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.column-title {
    font-family: 'Inter';
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    border-bottom: 1px solid #58595B;
}

.column-subtitle {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
    padding-top: 12px;
    padding-left: 0px;
}

.column-value {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
    padding-top: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #58595B;
}

.formfieldcolumn {
    font-family: 'Inter';
    padding: 0;
    padding-top: 24px;
    padding-bottom: 12px;
    vertical-align: middle;
}

.formfieldcolumn input {
    font-family: 'Inter';
    width: 95%;
    border-color: #58595B;
}


.referral-error {
    color: red;
    margin-top: 5px;
    font-size: 12px;
    width: 100%;
    text-align: center;
}



.success-message {
    color: #81D553;
}

.user-error {
    padding-top: 6px;
    font-size: 12px;
    color: red;
}

.welcome-title {
    margin-bottom: 10px;
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
}

.welcome-message {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    margin-top: 10px;
}

.welcome-subtitle {
    font-family: 'Inter';
    color: #58595B;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 20px;
}

.welcome-subtitle2 {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 20px;
}

.welcome-faqitem {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
}

.welcome-faqitemmessage {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
}

.welcome-buttons {
    margin-top: 20px;
}

.section-title {
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 0px;
}

.section-subtitle {
    font-family: 'Inter';
    color: #58595B;
    padding: 10px;
    padding-left: 0px;

    display: flex;
    align-items: center;
    justify-content: left;
}



#referrals-small {
    margin-top: 20px;
    visibility: hidden;
    display: none;
}

#referrals-large {
    visibility: visible;
    display: block;
}

#profile-small {
    visibility: hidden;
    display: none;
}

#profile-large {
    visibility: visible;
}

#payment-options-small {
    visibility: hidden;
    display: none;
}

#payment-options-large {
    visibility: visible;
}

@media screen and (max-width: 800px) {
    .App {
        padding: 10px;
        min-width: 0px;
    }

    #logo {
        width: 175px;
    }

    .AppContent {
        width: 100%;
        padding: 12 px;
    }
    
    .header {
        padding: 6px;
    }   

    .form-box {
        padding: 0px;
    }

    .form-box .title {
        margin-left: 0px;
        margin-right: 0px;
    }

    .formfield input {
        width: 100%;
    }

    #referrals-small {
        visibility: visible;
        display: block;
    }
    
    #referrals-large {
        visibility: hidden;
        display: none;
    }

    #profile-small {
        visibility: visible;
        display: block;
    }
    
    #profile-large {
        visibility: hidden;
        display: none;
    }

    #payment-options-small {
        visibility: visible;
        display: block;
    }
    
    #payment-options-large {
        visibility: hidden;
        display: none;
    }
}
*/