.screen.login {
    align-items: center;
}
.screen.login .screen-segment {
    width: 50%;
}

.screen.login .screen-segment.left {
    width: 500px;
    margin-left: 100px;
}

.screen.login .screen-segment.left .title {
    font-family: SpaceGrotesk;
    font-size: 55px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -1.3px;
    text-align: left;
    color: #fff;
  }

.screen.login .screen-segment.left .title .bold {
    color: #ede74e;
}

.screen.login .screen-segment.left .subtitle {
    margin-top: 15px;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #fff;
  }

  .screen.login .screen-segment.left .subtitle a, a:visited{
    color: white;
  }

  .screen.login .screen-segment.left .form-box {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  .screen.login .screen-segment.left .form-box input {
    height: 40px;
    width: 49%;
    border-radius: 0;
    border: none;
}
  

  .screen.login .screen-segment.left .buttons .button {
    width: 500px;
  }

  .screen.login .screen-segment.left .footer-message {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.22px;
    text-align: left;
    color: #fff;
  }

  .screen.login .screen-segment.left .footer-message .login {
    color: #ede74e;
    cursor: pointer;
  }

  .screen.login .screen-segment.left .footer-message a, .screen.login .screen-segment.left .footer-message a:visited, .screen.login .screen-segment.left .footer-message a:hover {
      color: #fff;
      text-decoration: none;
  }

.screen.login .screen-segment.right {
    position: relative;
    height: 100%;
}

.screen.login .screen-segment.right .card {
    position: absolute;
    padding: 38px;
    padding-right: 0px;
    width: 428px;
    height: 248px;
    border-radius: 40px;
    box-shadow: 0px 27px 50.7px 6.3px rgba(0, 0, 0, 0.33);
}

.screen.login .screen-segment.right .card .icon {
    width: 68px;
    height: 53.6px;    
}

.screen.login .screen-segment.right .card .icon img {
    width: 100%;
    height: 100%;
}

.screen.login .screen-segment.right .card .value {
    position: absolute;
    width: 180px;
    height: 92px;    
    font-family: Inter-ExtraLight;
    font-size: 89.6px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -7.23px;
    text-align: right;
    color: #fff;
    bottom: 38px;
    right: 50px;
}

.screen.login .screen-segment.right .card .value span {
    font-size: 72.5px;
    font-weight: 300;
    letter-spacing: -3.63px;
}

.screen.login .screen-segment.right .card.green {
    top: 50px;
    left: 100.1px;
    transform: rotate(-0.03turn);
    z-index: 1000;
    background-image: linear-gradient(to bottom right, #bfe97e, #81d553);
}

.screen.login .screen-segment.right .card.green .value {
    font-size: 90px;
    letter-spacing: -7.15px;
    right: 50px;
}

.screen.login .screen-segment.right .card.yellow {
    left: 197px;
    top: 210px;
    background-color: #ede74e;
}

.screen.login .screen-segment.right.desktop {
    display: flex;
}

.screen.login .screen-segment.right.mobile {
    display: none;
}