.screen.partner-intro {
    align-items: flex-start;
    justify-content: flex-start;
    //background-image: linear-gradient(to bottom left, #06285e, #0048be);
    background-color: #0048be;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    flex-direction: column;
    height: 100%;
    max-height: none;
    padding: 0;
    padding-top: 19vw;
}
.screen.partner-intro .screen-segment {
    width: 100%;
    height: auto;
}

.screen.partner-intro .screen-segment.left {
    flex: none;
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    background-color: #0048be;
}

.screen.partner-intro .screen-segment.left .title {
    font-family: SpaceGrotesk;
    font-size: 7vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.3px;
    text-align: left;
    color: #fff;
  }

.screen.partner-intro .screen-segment.left .title .bold {
    color: #ede74e;
}

.screen.partner-intro .screen-segment.left .subtitle {
    margin-top: 4vw;
    font-family: Inter;
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0px;
    text-align: left;
    color: #fff;
  }

  .screen.partner-intro .screen-segment.left .subtitle a, a:visited{
    color: white;
  }

  .screen.partner-intro .screen-segment.left .form-box {
    display: flex;
    margin-top: 2vw;
    flex-direction: column;
  }

  .screen.partner-intro .screen-segment.left .form-box input {
      height: 35px;
      padding: 5px;
      margin: 0;
      border-radius: 0;
      border: none;
      margin-bottom: 2vw;
      width: 100%;
      font-size: 3.5vw;
  }

  .screen.partner-intro .screen-segment.left .buttons {
    margin-top: 4vw;
  }

  .screen.partner-intro .screen-segment.left .buttons .button {
    width: 100%;
    font-size: 4vw;
    margin-top: 0;
    height:40px;
    padding: 10px;
  }

  .screen.partner-intro .screen-segment.left .footer-message {
    margin-top: 2vw;
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.22px;
    text-align: left;
    color: #fff;
  }

  .screen.partner-intro .screen-segment.left .footer-message .login {
    color: #ede74e;
    cursor: pointer;
  }

  .screen.partner-intro .screen-segment.left .footer-message a, .screen.partner-intro .screen-segment.left .footer-message a:visited, .screen.partner-intro .screen-segment.left .footer-message a:hover {
      color: #fff;
      text-decoration: none;
  }

.screen.partner-intro .screen-segment.right {
    position: relative;
    display: flex;
    flex: none;
    height: 64vw;
    margin: 0;
}

.screen.partner-intro .screen-segment.right .card {
    position: absolute;
    padding: 20px;
    padding-right: 0px;
    width: 61vw;
    height: 38vw;
    border-radius: 20px;
    box-shadow: 0px 27px 50.7px 6.3px rgba(0, 0, 0, 0.33);
}

.screen.partner-intro .screen-segment.right .card .icon {
    width: 10vw;
    height: 8vw;    
}

.screen.partner-intro .screen-segment.right .card .icon img {
    width: 100%;
    height: 100%;
}

.screen.partner-intro .screen-segment.right .card .value {
    position: absolute;
    width: auto;
    height: auto;    
    font-family: Inter-ExtraLight;
    font-size: 10vw;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -3.23px;
    text-align: right;
    color: #fff;
    bottom: 5vw;
    right: 5vw;
}

.screen.partner-intro .screen-segment.right .card .value span {
    font-size: 7vw;
    font-weight: 300;
    letter-spacing: -3.63px;
}

.screen.partner-intro .screen-segment.right .card.green {
    top: 5vw;
    left: 15vw;
    transform: rotate(-0.03turn);
    z-index: 1000;
    background-image: linear-gradient(to bottom right, #bfe97e, #81d553);
}

.screen.partner-intro .screen-segment.right .card.green .value {
    font-size: 10vw;
    letter-spacing: -3.15px;
    right: 5vw;
}

.screen.partner-intro .screen-segment.right .card.yellow {
    left: 27vw;
    top: 17vw;
    background-color: #ede74e;
}

.screen.partner-intro .screen-segment.right.desktop {
    display: none;
}

.screen.partner-intro .screen-segment.right.mobile {
    display: flex;
}