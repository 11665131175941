.split-screen-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.split-screen-background .left {
    flex: 1;
    background-color: #0052ca;
}

.split-screen-background .right {
    flex: 1;
    background-color: #fff;
}

.screen.onboarding {
    //border: 1px dashed #999;
}

.screen.onboarding .screen-segment.left {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}

.screen.onboarding .steps {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 65px;
}

.screen.onboarding .steps .step{
    display: flex;
    flex-direction: column;
}

.screen.onboarding .steps .step .step-title{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: left;
    color: #81d553;
    text-transform: uppercase;
    padding-left: 50px;
    margin-top: -30px;
}

.screen.onboarding .steps .step .step-label{
    font-family: Inter;
    font-size: 24px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
    padding-left: 50px;
}

.screen.onboarding .steps .step .step-text{
    display: none;
    margin-top: 10px;
    font-family: SpaceGrotesk;
    font-size: 65px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -1.3px;
    text-align: left;
    color: #fff;
    padding-left: 50px;
}

.screen.onboarding .steps .step.active .step-text{
    display: block;
}

.screen.onboarding .steps .step .step-divider {
    margin-top: 10px;
    width: 100%;
    height: 57px;
}

.screen.onboarding .steps .step .step-divider img {
    width: 100%;
    height: 100%;
}

.screen.onboarding .steps-progreess-bar {
    display: none;
}

.screen.onboarding .screen-segment.right {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    //border: 1px dashed #999;
}

.screen.onboarding.verify-email .form {
    padding: 85px;
}

.screen.onboarding.verify-email .form-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.verify-email .form-title span {
    font-weight: 600;
}

.screen.onboarding.verify-email .form-field {
    margin-top: 42px;
}

.screen.onboarding.verify-email .form-field input {
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    width: 82px;
    font-family: Inter;
    font-size: 24.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: center;
    color: #06285e;
}

.screen.onboarding.verify-email .buttons {
    margin-top: 27px;
}

.screen.onboarding.verify-email .buttons .button {
    width: 320px;
    height: 57px;
}

.screen.onboarding.verify-email .form .send-again {
    margin-top: 27px;
    font-family: Inter;
    font-size: 20px;
}

.screen.onboarding.verify-email .form .send-again span{
    cursor: pointer;
    color: #06285e;
    font-weight: bold;
}

// -----
.screen.onboarding.create-password .form {
    padding: 85px;
}

.screen.onboarding.create-password .form-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.create-password .form-fields {
    margin-top: 42px;
    display: flex;
    flex-direction: row;
}

.screen.onboarding.create-password .form-field input {
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    font-family: Inter;
    font-size: 24.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #06285e;
    margin-right: 20px;
}

.screen.onboarding.create-password .buttons {
    margin-top: 27px;
}

.screen.onboarding.create-password .buttons .button {
    width: 320px;
    height: 57px;
}

// Add you fried
.screen.onboarding.add-your-friend .screen-segment.right {
    justify-content: center;
    align-items: center;
}

.screen.onboarding.add-your-friend .screen-segment.right .form {
    width: 36vw;
    max-width: 738px;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .text {
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .item {
    margin-top: 38px;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .item .item-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .item .item-line input {
    width: 49%;
    height: 63px;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #06285e;
    font-family: Inter;
    font-size: 24.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .add-another {
    margin-top: 38px;
    font-family: Inter;
    font-size: 24.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #06285e;
    cursor: pointer;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .buttons {
    margin-top: 38px;
    flex-direction: column;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .buttons .button {
    width: 320px;
    height: 57px;
    font-family: SpaceGrotesk;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .skip {
    margin-top: 38px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
    cursor: pointer;
    width: 150px;
}

.referral-error {
    margin-top: 5px;
    padding-left: 5px;
    font-family: Inter;
    font-size: 3vw;
    color: red;
    text-align: center;
}