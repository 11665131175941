.screen.profile {
    align-items: flex-start;
    padding: 165px;
    //padding-right: 165px;
}

.screen.profile .screen-segment {
    background-color: #fff;
}

.screen.profile .screen-segment.left {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.screen.profile .screen-segment.left.empty-list {
    display: flex;
    justify-content: center;
} 

.screen.profile .screen-segment.left.empty-list .title {
    font-family: Inter;
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: -0.96px;
    text-align: left;
    color: #06285e;
    text-transform: none;
}

.screen.profile .screen-segment.left.empty-list .subtitle {
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.profile .screen-segment.left.empty-list .subtitle span {
    font-weight: 600;
}

.screen.profile .screen-segment.left.empty-list .buttons .button {
    width: 258px;
    height: 57px;
    font-family: SpaceGrotesk;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}


.screen.profile .screen-segment.left .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
}

.screen.profile .screen-segment.left .subtitle {
    margin-top: 32px;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.screen.profile .screen-segment.left .referral-list {
    margin-top: 68px;
    width: 100%;
    display: flex;
    
}

.screen.profile .screen-segment.left .referral-list .column-header{
    text-transform: uppercase;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #c7c8ca;
}

.screen.profile .screen-segment.left .referral-list-item {
    margin-top: 18px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #c7c8ca;
    height: 111px;
    flex-direction: column;
}

.screen.profile .screen-segment.left .referral-list-item .columns-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.screen.profile .screen-segment.left .referral-list-item .columns-row .column-value{
    font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  text-align: left;
  color: #0052ca;
}

.screen.profile .screen-segment.left .referral-list-item .column-value.center{
    text-align: center;
}

.screen.profile .screen-segment.left .referral-list-item .column-value.grey{
    color: #939598;
}

.screen.profile .screen-segment.left .referral-list-item .payments-row {
    display: flex;
    flex-direction: row;
    margin-top: 26px;
    align-content: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #939598;
}

.screen.profile .screen-segment.left .referral-list-item .payments-row .payment-column  {
    display: flex;
    flex-direction: row;
    width: 200px;
    margin-right: 34px;
    align-items: center;
}

.screen.profile .screen-segment.left .referral-list-item .payments-row .payment-column img {
    width: 30px;
    height: 30px;
    margin-right: 19px;
}

.screen.profile .screen-segment.left .referral-list-item .payments-row .payment-column input {
    width: 20px;
    height: 20px;
    margin-right: 19px;
    margin-top: -3px;
}

.screen.profile .screen-segment.right {
    width: 50%;
    align-self: stretch;
    margin-left: 67px;
}

.screen.profile .pagination {
    font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  text-align: left;
  color: #939598;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
    margin-top: 20px;
}

.screen.profile .pagination .page-size {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}

.screen.profile .pagination .page-size .label {
    padding-right: 10px;
    height: 44px;
    vertical-align: middle;
    padding-top: 10px;
    text-align: right;
}

.screen.profile .pagination .page-size .p-dropdown {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #939598;
    color: #939598;
    padding-left: 0px;
}

.screen.profile .pagination .page-size .p-dropdown-label {
    color: #939598;
}

.screen.profile .pagination .page-list {
    display: flex;
    flex-direction: row;
}

.screen.profile .pagination .page {
    padding: 5px;
    cursor: pointer;
}

.screen.profile .pagination .page.current {
    font-weight: bold;
    cursor: default;
}

.screen.profile .pagination .divider {
    padding: 5px;
}