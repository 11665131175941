body {
    
    display: flex;
    font-family: 'SpaceGrotesk';
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;    

}

.App.external {
    background-image: linear-gradient(to bottom left, #06285e, #0048be);
    color: #0052CB;
}

.App.internal {
    background-image: none;
    color: #fff;
}

.screen {
    position: relative;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    max-height: 1080px;
    display: flex;
    align-items: flex-start;
    //border: 1px dashed #999999;
    padding-top: 150px;
}

.screen-segment {
    width: 100%;
}

.header {
    position: absolute;
    top: 69px;
    left: 0px;
    padding-left: 103px;
    padding-right: 103px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.header .cogs {
    display: none;
}

.header .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header .user-info .name-and-logout {
    display: flex;
    flex-direction: column;
    margin-right: 21px;
}

.header .user-info .name-and-logout .name {
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    text-align: left;
    color: #06285e;
}

.header .user-info .name-and-logout .logout {
    
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.3px;
    text-align: right;
    color: #06285e;
    cursor: pointer;
    text-decoration: underline;

}

.header .user-info .name span {
    color: #0052ca;
    font-weight: bold;
}

.header .user-info img {
    width: 43px;
    height: 43px;
    cursor: pointer;
}

