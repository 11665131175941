.screen.profile {
    align-items: flex-start;
    padding: 0;
    padding-top: 25vw;
    //padding-right: 165px;
}

.screen.profile .screen-segment {
    background-color: #fff;
    padding: 0;
}

.screen.profile .screen-segment.left {
    display: flex;
    flex-direction: row;
    align-self: stretch;
}

.screen.profile .screen-segment.left .tab-spacer{
    width: 15px;
    height: 73px;
    border-bottom: 2px solid #80c0ff;
}

