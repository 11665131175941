.split-screen-background {
    display: none;
}

.screen.onboarding .screen-segment.left {
    background-color: #0052ca;
    padding-top: 11vh;
    justify-content: center;
    height: auto;
}

.screen.onboarding .steps {
    margin: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 1vh;
    height: auto;
}

.screen.onboarding .steps .step{
    display: none;
}

.screen.onboarding .steps .step.active{
    display: flex;
}

.screen.onboarding .steps .step .step-title{
    padding-left: 0px;
    margin-top: 0;
    font-size: 3.5vw;
}

.screen.onboarding .steps .step .step-label{
    padding-left: 0px;
    font-size: 5vw;
}

.screen.onboarding .steps .step .step-text{
    padding-left: 0px;
    font-size: 8vw;
}

.screen.onboarding .steps .step .step-divider {
    display: none;
}

.screen.onboarding .steps .step .step-divider img {
    width: 100%;
    height: 100%;
}

.screen.onboarding .steps-progreess-bar {
    background-color: #81d553;
    height: 1vw;
    width: 100%;
    display: block;
}

.screen.onboarding .screen-segment.right {
    display: flex;
    height: auto;
    align-items: flex-start;
    padding-top: 3vw;
}

.screen.onboarding.verify-email .form {
    padding: 0px;
    width: 100%;
    max-width: none;
}

.screen.onboarding.verify-email .form-title {
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.verify-email .form-title span {
    font-weight: 600;
}

.screen.onboarding.verify-email .form-field {
    margin-top: 4vw;
}

.screen.onboarding.verify-email .form-field input {
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    width: 100%;
    height: 40px;
    padding: 5px;
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 10px;
    text-align: left;
    color: #06285e;
}

.screen.onboarding.verify-email .buttons {
    margin-top: 4vw;
}

.screen.onboarding.verify-email .buttons .button {
    width: 100%;
    font-size: 3.5vw;
    margin-top: 0;
    height: 40px;
    padding: 10px;
}

.screen.onboarding.verify-email .form .send-again {
    margin-top: 4vw;
    font-family: Inter;
    font-size: 3.5vw;
}

.screen.onboarding.verify-email .form .send-again span{
    cursor: pointer;
    color: #06285e;
    font-weight: bold;
}

// -----
.screen.onboarding.create-password .form {
    padding: 0;
}

.screen.onboarding.create-password .form-title {
    font-family: Inter;
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
    
}

.screen.onboarding.create-password .form-fields {
    margin-top: 5vw;
    display: flex;
    flex-direction: column;
}

.screen.onboarding.create-password .form-field input {
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    font-family: Inter;
    font-size: 4vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #06285e;
    margin-right: 0px;
    margin-bottom: 5vw;
    height: 45px;
    padding: 5px;
}

.screen.onboarding.create-password .buttons {
    margin-top: 5vw;
}

.screen.onboarding.create-password .buttons .button {
    width: 100%;
    font-size: 4vw;
    margin-top: 0;
    height: 40px;
    padding: 10px;
}

// Add you fried
.screen.onboarding.add-your-friend .screen-segment.right {
    align-items: flex-start;
    padding-top: 3vw;
}

.screen.onboarding.add-your-friend .screen-segment.right .form {
    width: 100%;
    max-width: none;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .text {
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .item {
    margin-top: 2vw;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .item .item-line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .item .item-line input {
    width: 100%;
    height: 40px;
    padding: 5px;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #06285e;
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #0052ca;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .add-another {
    margin-top: 4vw;
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #06285e;
    cursor: pointer;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .buttons {
    margin-top: 4vw;
    flex-direction: column;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .buttons .button {
    font-family: SpaceGrotesk;
    font-size: 3.5vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;

    width: 100%;
    font-size: 4vw;
    margin-top: 0;
    height: 40px;
    padding: 10px;
}

.screen.onboarding.add-your-friend .screen-segment.right .form .skip {
    margin-top: 4vw;
    font-family: Inter;
    font-size: 3.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
    cursor: pointer;
}

.referral-error {
    margin-top: 5px;
    padding-left: 5px;
    font-family: Inter;
    font-size: 14px;
    color: red;
    text-align: center;
}