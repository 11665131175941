.screen.home {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
}

.screen.home .screen-segment {
    background-color: #fff;
    width: 100%;
    height: 100%;
}

.screen.home .screen-segment.left {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.screen.home .screen-segment.left.empty-list {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20vw;
} 

.screen.home .screen-segment.left.empty-list .title {
    font-family: Inter;
    font-size: 8vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.96px;
    text-align: left;
    color: #06285e;
    text-transform: none;
    margin-top: auto;
}

.screen.home .screen-segment.left.empty-list .subtitle {
    font-family: Inter;
    font-size: 4vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
}

.screen.home .screen-segment.left.empty-list .subtitle span {
    font-weight: 600;
}

.screen.home .screen-segment.left .buttons .button {
    width: 100%;
    height: 57px;
    font-family: SpaceGrotesk;
    font-size: 4vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}


.screen.home .screen-segment.left .title {
    font-family: Inter;
    font-size: 4vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
    margin-top: 5vw;
}

.screen.home .screen-segment.left .subtitle {
    display: none;
}

.screen.home .screen-segment.left .referral-list {
    margin-top: 5vw;
    width: 100%;
    display: flex;
    
}

.screen.home .screen-segment.left .referral-list-item  .item-header-row {
    font-family: Inter;
    font-size: 4vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #0052ca;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.screen.home .screen-segment.left .referral-list-item  .header-row-title {
    display: flex;
    flex-direction: column;
}

.screen.home .screen-segment.left .referral-list-item  .item-header-row span.completed {
    font-size: 3.5vw;
    font-weight: normal;
    color: #81d553;
}

.screen.home .screen-segment.left .referral-list-item  .item-header-row span.pending {
    font-size: 3.5vw;
    font-weight: normal;
    color: #0052ca;
}

.screen.home .screen-segment.left .referral-list-item  .header-row-icon {
    color: #939598;
    font-size: 2vw;
}

.screen.home .screen-segment.left .referral-list-item  .item-body-row {
    margin-top: 0vw;
    font-family: Inter;
    font-size: 3vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #939598;
}

.screen.home .screen-segment.left .referral-list-item  .item-body-row-line {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1vw;
}

.screen.home .screen-segment.left .referral-list-item  .item-body-row-line .row-title {
    width: 30%;
}

.screen.home .screen-segment.left .referral-list-item  .item-body-row-line .row-value {
    width: 70%;
    color: #0052ca;
}

.screen.home .screen-segment.left .referral-list-item .columns-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}

.screen.home .screen-segment.left .referral-list-item .columns-row .column-value{
    font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  text-align: left;
  color: #0052ca;
}

.screen.home .screen-segment.left .referral-list-item .column-value.center{
    text-align: center;
}

.screen.home .screen-segment.left .referral-list-item .column-value.grey{
    color: #939598;
}

.screen.home .screen-segment.left .referral-list-item .payments-row {
    display: flex;
    flex-direction: row;
    margin-top: 5vw;
    align-content: center;
    font-family: Inter;
    font-size: 3vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3vw;
    letter-spacing: -0.18px;
    text-align: left;
    color: #939598;
}

.screen.home .screen-segment.left .referral-list-item .payments-row .payment-column  {
    display: flex;
    flex-direction: row;
    width: auto;
    margin-right: 20px;
    align-items: center;
}

.screen.home .screen-segment.left .referral-list-item .payments-row .payment-column img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.screen.home .screen-segment.left .referral-list-item .payments-row .payment-column input {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    margin-top: 0px;
}

.screen.home .screen-segment.right {
    display: none;
}

.screen.home .pagination {
    display: none;
}

.screen.home .pagination .page-size {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5vw;
}

.screen.home .pagination .page-size .label {
    padding-right: 10px;
    height: 44px;
    vertical-align: middle;
    padding-top: 10px;
    text-align: right;
}

.screen.home .pagination .page-size .p-dropdown {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #939598;
    color: #939598;
    padding-left: 0px;
    height: 25px;
}

.screen.home .pagination .page-size .p-dropdown-label {
    color: #939598;
    font-size: 3vw;
}

.screen.home .pagination .page-list {
    display: flex;
    flex-direction: row;
}

.screen.home .pagination .page {
    padding: 5px;
    cursor: pointer;
}

.screen.home .pagination .page.current {
    font-weight: bold;
    cursor: default;
}

.screen.home .pagination .divider {
    padding: 5px;
}

.screen.home .screen-segment.left .referral-list.desktop {
    display: none;
}

.screen.home .screen-segment.left .referral-list-item.desktop {
    display: none;
}

.screen.home .screen-segment.left .referral-list-item.mobile {
    display: flex;
}

.screen.home .screen-segment.left .earnings {
    display: flex;
}