.screen.partner-form {
    background-color: #fff;
    height: 100%;
}

.screen.partner-form .screen-segment.left {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
}

.screen.partner-form .screen-segment.left .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
}

.screen.partner-form .screen-segment.left .text {
    margin-top: 20px;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.screen.partner-form .screen-segment.left .form {
    margin-top: 20px;
    padding: 20px;
    border-radius: 25px;
    border: solid 2px #c7c8ca;
    background-color: #fff;
}

.screen.partner-form .screen-segment.left .form .item{
    margin-top: 10px;
}

.screen.partner-form .screen-segment.left .form .item .item-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.screen.partner-form .screen-segment.left .form .item .item-line input {
    width: 24%;
    border: none;
    border-bottom: 2px solid #c7c8ca;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    border-radius: 0;
}

.screen.partner-form .screen-segment.left .form .referral-error {
    font-size: 16px;
}


.screen.partner-form .screen-segment.left .form .add-another {
    margin-top: 20px;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    cursor: pointer;
}

.screen.partner-form .screen-segment.left .form .buttons .button {
    margin-top: 20px;
    width: 183px;
    height: 57px;
    font-family: SpaceGrotesk;
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.screen.partner-form .screen-segment.left .questions {
    margin-top: 20px;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.screen.partner-form .screen-segment.left .questions a, .screen.partner-form .screen-segment.left .questions a:visited {
    font-weight: bold;
}

