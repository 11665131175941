@font-face {
    font-family: 'SpaceGrotesk';
    src: local('SpaceGrotesk'), url(./fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./fonts/Inter-VariableFont_slnt_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-ExtraLight';
    src: local('Inter-ExtraLight'), url(./fonts/Inter-ExtraLight.ttf) format('truetype');
}  